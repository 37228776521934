import React from "react";
import "./BookAppointment.css";
import { Link } from "react-router-dom";

const BackPageBtn = ({backLink}) => {
  return (
    <>
      <div className="row mt-4">
        <div className="col">
          <div className="backLinkDiv1 pl-4">
            <Link to={backLink} className="backLinkAn">
              Back
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackPageBtn;
