import React, { useEffect, useState } from "react";
import "./All.css"; 
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import HomeServices from "../../Services/HomeServices";
import moment from "moment";
import BackPageBtn from "../BackButton/BackPageBtn";
import { Tab, TabList, Tabs } from "react-tabs";
const NewRes = () => {
  const [blockedIds, setBlockIds] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [tabData, setTabData] = useState("phone");
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [blockDateTime, setBlockDateTime] = useState({
    block_date: "",
    block_time: [],
  });
  const [timeScheduleArr, setTimeScheduleArr] = useState([
    {
      serHead: "Timing",
      serTimeArr: [],
    },
  ]);
  const [check, setCheck] = useState("");

  const handleCheck = (i) => {
    setCheck((prev) => (prev ? (prev === i ? "" : i) : i));
  };

  const [allTime, setAllTime] = useState([]);
  // console.log("adsdasd", allTime);
  const location = useLocation();
  console.log("ndvjsd7654723m", location?.state?.BookTypeId?._id);
  const dateTimeField = {
    date: new Date(),
    time: "",
  };

  const [timing_id, setTimeId] = useState({ timing_id: "" });
  const [dateTime, setDateTime] = useState(dateTimeField);
  const [modalA, setModalA] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [viewBlockdate, setViewBlockDate] = useState([]);
  const [BlockedTime, setBlockedTime] = useState([]);
  console.log("32dfsd455",BlockedTime)



  console.log("check", check);

  const handleDateClick = (value) => {
    setSelectedDate(value);
  };

  const tileClassName = ({ date, view }) => {
    // Add a custom class to the active (selected) date
    if (
      selectedDate &&
      date.getDate() === selectedDate.getDate() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getFullYear() === selectedDate.getFullYear()
    ) {
      return "active-date";
    }
    // Add a custom class to the current date (optional)
    if (
      view === "month" &&
      date.getDate() === new Date().getDate() &&
      date.getMonth() === new Date().getMonth() &&
      date.getFullYear() === new Date().getFullYear()
    ) {
      return "current-date";
    }
    return null;
  };

  const handleTimeSelection = (selectedTime) => {
    console.log("selectedTime", selectedTime);
    if (selectedTime && typeof selectedTime.time === "string") {
      setDateTime({
        ...dateTime,
        time: selectedTime._id,
      });
    } else {
      console.error("Invalid time format:", selectedTime);
    }
  };

  const fetchTime = async () => {
    try {
      const response = await HomeServices.getTime();
      // Call your API function
      if (response && response?.status) {
        const timeSlots = response.data;
        setTimeScheduleArr([
          {
            serHead: "Timing",
            serTimeArr: timeSlots,
          },
        ]);
        console.log("getTimeResponse", response?.data);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  const availableByDatetime = async (date) => {
    console.log("first684987984");
    let data = {
      booking_serviceType_id: serviceType,
      date: date,
    };
    const res = await HomeServices.viewBookingTimeByDate(data);
    console.log("jksdfaqtbdk", res);
    if (res && res?.status) {
      // setServiceDateTime(res?.data);
      const timeSlots = res.data;
      setTimeScheduleArr([
        {
          serHead: "Timing",
          serTimeArr: timeSlots,
        },
      ]);
    }
  };


  useEffect(() => {
    fetchTime();
    GetAllDate(new Date());
  }, []);

  useEffect(() => {
    if (selectedDate) {
      availableByDatetime(selectedDate);
    } else {
      fetchTime();
    }
  }, [selectedDate]);

  const GetAllDate = async (date) => {
    let data = {
      booking_serviceType_id: location?.state?.BookTypeId?._id,
      date: moment(date).format("YYYY-MM-DD"),
    };
    const res = await HomeServices.viewBookingTimeByDate(data);
    // console.log("mmfnbkgjsr",res)
    if (res && res?.status) {
      setAllTime(res?.data);
    } else {
      toast.error("backend error");
    }
  };

  function openSetTimeModal() {
    setModalA(true);
  }

  const blockDate = (date) => {
    const formatdate = moment(date).format("YYYY-MM-DD");
    const check = viewBlockdate.find((item) => item?.block_date === formatdate);
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  const isWeekend = (date) => date.getDay() === 0 ||  blockDate(date);
  const handleDateChange = async (date) => { 
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (date < currentDate) {
      toast.error("Please select a Current Date or Upcoming Date.");
    } else if (isWeekend(date)) {
      toast.error("You can't select a date on Saturday or Sunday!");
    } else {
      viewBlockByDateTime(moment(date).format("YYYY-MM-DD"))
      GetAllDate(date);
      setDateTime({
        ...dateTime,
        date: date,
      });
    }
  };

  const tileDisabled = ({ date }) => isWeekend(date);

  const onSUbmit = () => {
    if (dateTime.date && dateTime.time) {
      if (typeof dateTime.time === "string") {
        const fullDateTime = new Date(dateTime.date);
        const [hours, minutes] = dateTime.time.split(":");
        fullDateTime.setHours(parseInt(hours), parseInt(minutes));
        console.log("dateeeeeee", dateTime); 
      } else {
        toast.error("Invalid time format");
      }
    } else {
      toast.error("Please Select the Date and Time");
      console.error("Please select both date and time before submitting.");
    }
  };

  const onSUbmitReschedule = async () => {
    let data = {
      date: moment(dateTime?.date)?.format("YYYY-MM-DD"),
      timing_id: timing_id?.timing_id,
    };
    const res = await HomeServices.rescheduleBooking(location.state?.id, data);
    if (res && res.status) {
      toast.success("Appointment Reschedule Successfully");

      setTimeout(() => {
        navigate("/view-user-appointment");
      }, 2000);
    } else {
      toast.error("Unable to Reschedule Appointment");
    }
    // console.log("rescheduleData546", res);
  }; 



  // diabled date
  const fetchViewBlockDate = async () => {
    const res = await HomeServices.viewBlockdate();
    if (res && res?.status) {
      setViewBlockDate(res?.data);
    }
  };

  // bisabled time according to date
  const viewBlockByDateTime = async (date) => {
    console.log("hbvjhg7637ghjsdv67",date)
    let data = {
      block_date: date,
    };
    const res = await HomeServices.viewblockByDateAndTime(data);
    // checkDate(date);
    console.log("6546esponse", res);
    if (res && res?.status) {
      if (res?.data?.length !== 0) {
        setBlockedTime(res?.data?.[0]?.bookingTime);
      } else {
        setBlockedTime([]);
      }
    }
  };

  const checkedBook = (id) => {
    // console.log("BlockedTimeuse", BlockedTime);
    const check = BlockedTime?.find((item) => item?._id === id);
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (e < currentDate) {
      toast.error("Please select a Current Date or Upcoming Date.");
    } else if (isWeekend(e)) {
      toast.error("You can't select a date on Saturday or Sunday!");
    } else {
      setBlockDateTime({
        ...blockDateTime,
        block_date: moment(e).format("YYYY-MM-DD"),
      });
      setSelectedDate(moment(e).format("YYYY-MM-DD"));
      viewBlockByDateTime(moment(e).format("YYYY-MM-DD"));
      availableByDatetime(moment(e).format("YYYY-MM-DD"));
    }
  };


  const toggleSelectedTime = (timeId) => {
    if (selectedTimes.includes(timeId)) {
      setSelectedTimes((prevSelected) =>
        prevSelected.filter((id) => id !== timeId)
      );
    } else {
      setSelectedTimes((prevSelected) => [...prevSelected, timeId]);
    }
  };

  useEffect(() => {
    fetchViewBlockDate();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            <BackPageBtn backLink="/view-user-appointment" />
            <div className="tablistDiv">
              <p className="seleSerText">Select a service</p>
              <div className="slectAftTab">
                <Tabs>
                  <TabList>
                    <Tab onClick={() => setTabData("phone")}>Phone</Tab>
                    <Tab onClick={() => setTabData("in_person_office")}>
                      In Person - Office
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
            <section className="predateSection">
              <div className="cust_container">
                <div className="row">
                  <div className="col-12">
                    <p className="slYuHead">Reschedule Date and Time</p>
                    {/* <div className="selectClndrDiv">
                      <Calendar
                        onChange={handleDateChange}
                        value={dateTime?.date}
                        tileDisabled={tileDisabled}
                        onClickDay={handleDateClick}
                        tileClassName={tileClassName}
                      />
                    </div> */}
                    <div className="selectClndrDiv">
                      <Calendar
                        onChange={handleChange}
                        value={blockDateTime?.date}
                        tileDisabled={tileDisabled}
                      />
                    </div>

                    {/* <div className="timeScheduleDiv">
                      {timeScheduleArr.map((value, index, arr) => {
                        console.log("timeScheduleArr", value?.serTimeArr);
                        return (
                          <div
                            style={{
                              marginBottom: `${
                                index === arr.length - 1 ? 0 : 18
                              }px`,
                            }}
                          >
                            <p className="sevMorHead">{value.serHead}</p>
                            <div
                              className="sevTimeAmDiv "
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {}}
                            >
                              {value.serTimeArr.length === 0 ? (
                                <div className="sevTimeAm">
                                  <span>All Booked</span>
                                </div>
                              ) : (
                                allTime.map((item, i) => {
                                  console.log("kljsfhgisu6348", item);
                                  return (
                                    <div
                                      key={i}
                                      className={`sevTimeAm ${ 
                                        item?.isBooked === true
                                          ? "sevTimeAmSelectColor"
                                          : check === i + 1
                                          ? "sevTimeAmSelectColor"
                                          : checkedBook(item?._id) === true
                                          ? "sevTimeAmSelectColor" 
                                          : "sevTimeAmColor" 
                                      }`} 
                                      onClick={() => {
                                        handleTimeSelection(item);
                                        setTimeId({
                                          ...timing_id,
                                          timing_id: item?._id,
                                        });
                                      }}
                                    >
                                      {item?.isBooked === true ? (
                                        <span>
                                          <i
                                            class="fa-solid fa-check fa-xs"
                                            style={{ color: "#ff9c00" }}
                                          ></i>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span
                                      className={
                                        checkedBook(item?._id) === true
                                          ? "disableDiv"
                                          : ""
                                      } 
                                        onClick={() => handleCheck(i + 1)} 
                                      >
                                        {item?.time}
                                        {checkedBook(item?._id) ? (
                                          <span
                                            className={
                                              checkedBook(item?._id)
                                                ? "disableDiv"
                                                : "sevTimeAmSelectColor"
                                            }
                                            style={{ color: "#4d7902" }}
                                          > 
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>{" "}
                                      {item?.isBooked === true ? (
                                        <span
                                          style={{
                                            color: " #4d7902",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          Booked
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div> */}

<div className="timeScheduleDiv">
                      {timeScheduleArr.map((value, index, arr) => {
                        console.log("timeScheduleArr", value);
                        return (
                          <div
                            style={{
                              marginBottom: `${
                                index === arr.length - 1 ? 0 : 18
                              }px`,
                            }}
                          >
                            <p className="sevMorHead">{value.serHead}</p>
                            <div
                              className="sevTimeAmDiv "
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {}}
                            >
                              {value.serTimeArr.length === 0 ? (
                                <div className="sevTimeAm">
                                  <span>All Booked</span>
                                </div>
                              ) : (
                                value?.serTimeArr?.map((item, i) => {
                                  console.log("itemfgs16", item);
                                  return (
                                    <div
                                      key={i}
                                      className={`sevTimeAmBlock ${
                                        item?.isBooked === true
                                          ? "sevTimeAmSelectColor"
                                          : check === i + 1
                                          ? "sevTimeAmSelectColor"
                                          : checkedBook(item?._id) === true
                                          ? "sevTimeAmSelectColor"
                                          : "sevTimeAmColor"
                                      }`}
                                      
                                      onClick={() => handleTimeSelection(item)}
                                    >
                                        {item?.isBooked === true ? (
                                        <span>
                                          <i
                                            class="fa-solid fa-check fa-xs"
                                            style={{ color: "#ff9c00" }}
                                          ></i>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span
                                        className={
                                          checkedBook(item?._id) === true
                                            ? " "
                                            : ""
                                        }
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setBlockIds((prev) => [
                                            ...prev,
                                            item?._id,
                                          ]);
                                          toggleSelectedTime(item?._id);
                                        }}
                                      >
                                        {selectedTimes.includes(item?._id) && (
                                          <span>&#10003;</span>
                                        )}
                                        {item?.time}
                                        {checkedBook(item?._id) ? (
                                          <span
                                            className={
                                              checkedBook(item?._id)
                                                ? "disableDiv"
                                                : "sevTimeAmSelectColor"
                                            }
                                            style={{
                                              color: "#4d7902",
                                              fontWeight: "bold",
                                            }}
                                          > 
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>{" "}
                                      {item?.isBooked === true ? (
                                        <span
                                          style={{
                                            color: " #4d7902",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Booked
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="serMDiv">
                      <div>
                        <button
                          onClick={onSUbmitReschedule}
                          style={{ border: "none" }}
                          className="joinWaitLink m-2"
                        >
                          Reschedule
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </>
  );
};

export default NewRes;
