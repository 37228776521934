import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import HomeServices from "../../Services/HomeServices";
import "./All.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UrgentAppointment = () => {
  const navigate = useNavigate();
  const [UrgenttData, setUrgenttData] = useState([])
  const [isRescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  console.log("mlkvnbui78587hn", UrgenttData)
  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    // { name: "Date", selector: "date", sortable: true, },
    { name: "Name", selector: "firstName", sortable: true },
    { name: "Email", selector: "email", sortable: true },
    { name: "Contact", selector: "mobileNumber", sortable: true },
    { name: "Appointment Date", selector: "bookedDate", sortable: true },
    { name: "Appointment Time", selector: "bookedTime", sortable: true },
    { name: "Status", selector: "isStatus", sortable: true },
    { name: "Action", selector: "action", sortable: true },
  ];

  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewAllUrgentAppointment();
      if (response && response?.status) {
        const appointments = response?.data;
        console.log("Response98653vvvvv2", appointments);
        let arr = appointments?.reverse()?.map((ele, index) => {
          console.log("56465sdbjhksd", ele?._id);
          return {
            sln: index + 1,
            // date: moment(ele?.createdAt).utc().format('DD-MM-YYYY'),
            firstName: `${ele?.firstName} ${ele?.lastName}`,
            // lastName: ele?.lastName,
            email: ele?.email,
            mobileNumber: ele?.mobileNumber,
            bookedDate: moment(ele?.date).format('DD-MM-YYYY'),
            bookedTime: ele?.BookingTime,
            // countryName: ele?.country,
            // bookingDate: ele?.date,
            // bookingTime: ele?.BookingTime,
            isStatus: (
              <div>
                {ele?.isCancelled === true && (
                  <p style={{ color: "red", fontWeight: "bold" }}>Cancelled</p>
                )}

                {ele?.isSchedule === true && (
                  <p style={{ color: "#ff9c00", fontWeight: "bold" }}>
                    Rescheduled
                  </p>
                )}
              </div>
            ),
            action: (
              <>
                <span
                  title="View Appointment Detail"
                  onClick={(e) => handleView(e, ele)}
                >
                  <i
                    className="fa-regular fa-eye"
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      marginRight: "5px",
                      fontSize: "1.5em",
                      color: " #477DA5",
                      backgroundColor: "#E9F3F7",
                    }}
                  ></i>
                </span>

                <span
                  title="Reschedule Appointment"
                  onClick={() => openRescheduleModal(ele)}
                >
                  <i
                    className="fa-solid fa-calendar-days"
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#ff9c00",
                      backgroundColor: "#fee5d2",
                    }}
                  ></i>
                </span>

                <span
                  title="Cancel Appointment"
                  onClick={() => handleViewCancel(ele?._id)}
                >
                  <i
                    className="fa-regular fa-rectangle-xmark"
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#C4554D",
                      backgroundColor: "#FAECEC",
                    }}
                  ></i>
                </span>
              </>
            ),
            // ),
          };
        });
        setUrgenttData(arr);
        // setDateFilter(arr)?.split()?.reverse();
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  const handleView = (e, ele) => {
    console.log("56465sdbjhksd9878", ele);
    e.preventDefault();
    navigate("/view-urgent-booked-appointment-data", { state: ele });
  };

  const handleViewCancel = async (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
      }).then((result) => {
        console.log("result", result);
        if (result?.isConfirmed) {
          HomeServices.cancelAppointmentById(id)
            .then((res) => {
              console.log("deleteBlogresponse", res);
              if (res && res.status) {
                toast.success("Appointment Cancelled Successfully");
                fetchData();
              } else {
                toast.error(res?.message);
              }
            })
            .catch((err) => {
              console.error("Error Cancelling Appointment:", err);
            });
        } else {
          console.log("Error while Cancellation");
        }
      });
    }

  };

  const openRescheduleModal = (ele) => {
    console.log("mnbcxgdfhwtr214", ele)
    // return
    navigate("/urgent-reschedule-appointment", {
      state: { id: ele?._id, BookTypeId: ele?.bookingAppointmentService },
    });
    setRescheduleModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">

          <section className="piechartsBox_area">
            <div>
              <div className="dateListDiv">
                <h1
                  style={{
                    color: "#ff9c00",
                    fontSize: "25px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  View Urgent Booking Appointments
                </h1>

                {/* <div className="tabRngeDivmain">
                <div className="strtdivmain"> 
                  <p className="strttxt">Start Date</p>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleStartDateChange} 
                  />
                </div> 
                <div className="strtdivmain">
                  <p className="strttxt">End Date</p>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleEndDateChange} 
                  />
                </div>
                <button
                  onClick={filterDataByDateRange}
                  className="Search_icon btn btn-primary"
                  type="button"
                >
                  Search
                </button>

                <CSVLink
                  className="dwdtxtflxdiv"
                  data={prepareDataForCSV()}
                  filename={"table_data.csv"}
                >
                  <h5>Download CSV File</h5>
                  <i class="fa-regular fa-circle-down"></i>
                </CSVLink>
              </div> */}
              </div>
            </div>

            {/* <BackPageBtn backLink="/" /> */}
            <hr />
            {/* <DataTable
              columns={columns}
              data={UrgenttData}
              pagination
              searchable
            // customStyles={customStyles}
            /> */}
            {UrgenttData.length > 0 && (
              <DataTable
                columns={columns}
                data={UrgenttData}
                pagination
                searchable
              />
            )}
          </section>
        </div>
      </div>
    </>
  )
}

export default UrgentAppointment