import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import HomeServices from "../../Services/HomeServices";
import moment from "moment-timezone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Tab, TabList, Tabs } from "react-tabs";
import "./All.css";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import BackPageBtn from "../BackButton/BackPageBtn";

const UrgentBlockDateTime = () => {
  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    { name: "Blocked Date", selector: "block_date", sortable: true },
    { name: "Action", selector: "action", sortable: true },
  ];
  const [timeScheduleArr, setTimeScheduleArr] = useState([
    {
      serHead: "Timing",
      serTimeArr: [],
    },
  ]);
  const [check, setCheck] = useState("");
  const [modal, setModal] = useState(false);
  const [blockedDateTimeModal, setBlockedDateTimeModal] = useState(false);
  const [adminBlockedDate, setAdminBlockedDate] = useState([]);

  const [serviceDateTime, setServiceDateTime] = useState([]);
  const [blockDateTime, setBlockDateTime] = useState({
    block_date: "",
    block_time: [],
  });
  const [BlockedTime, setBlockedTime] = useState([]);
  const [blockedIds, setBlockIds] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [serType, setServType] = useState("");
  const [serType1, setServType1] = useState("");
  const [tabData, setTabData] = useState("phone");
  const [viewBlockdate, setViewBlockDate] = useState([]);

  console.log("timeScheduleAr654r", BlockedTime);

  const [allTime, setAllTime] = useState([]);
  // console.log("adsdasd", allTime);
  const location = useLocation();
  const dateTimeField = {
    date: new Date(),
    time: "",
    value: location?.state,
  };

  const [dateTime, setDateTime] = useState(dateTimeField);
  const [modalA, setModalA] = useState(false);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleTimeSelection = (selectedTime) => {
    console.log("selectedTime", selectedTime);
    if (selectedTime && typeof selectedTime.time === "string") {
      setDateTime({
        ...dateTime,
        time: selectedTime._id,
      });
    } else {
      console.error("Invalid time format:", selectedTime);
    }
  };

  const fetchTime = async () => {
    try {
      const response = await HomeServices.getTime();
      // Call your API function
      console.log("khsg78", response);
      if (response && response?.status) {
        const timeSlots = response.data;
        setTimeScheduleArr([
          {
            serHead: "Timing",
            serTimeArr: timeSlots,
          },
        ]);
        console.log("getTimeResponse", response?.data);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  function openSetTimeModal() {
    setModalA(true);
  }
  const viewBlockByDateTime = async (date) => {
    let data = {
      block_date: date,
      booking_serviceType_id: serviceType,
    };
    const res = await HomeServices.viewUrgentBlockByDateAndTime(data);
    checkDate(date);
    console.log("6546es45ponse", res);
    if (res && res?.status) {
      if (res?.data?.length !== 0) {
        setBlockedTime(res?.data?.[0]?.bookingTime);
      } else {
        setBlockedTime([]);
      }
    }
  };

  const toggleSelectedTime = (timeId) => {
    if (selectedTimes.includes(timeId)) {
      setSelectedTimes((prevSelected) =>
        prevSelected.filter((id) => id !== timeId)
      );
    } else {
      setSelectedTimes((prevSelected) => [...prevSelected, timeId]);
    }
  };

  const fetchBlockDateTime = async (e) => {
    e.preventDefault();
    let data = {
      block_date: blockDateTime?.block_date,
      block_time: blockedIds,
      booking_serviceType_id: serviceType,
    };
    const res = await HomeServices.urgentBlockDateTime(data);
    console.log("first746358jhjhk23656", blockDateTime);
    if (res && res?.status) {
      // console.log("jdankjkbhkd2356782",res?.data?.block_date)
      toast.success("Appointment Blocked Successfully");

      viewBlockByDateTime();
      setSelectedTimes("");
      setBlockIds([]);
      setBlockDateTime("");
      setBlockedTime([]);
      fetchViewBlockDate();
    }
  };

  // check blocked or not
  const checkedBook = (id) => {
    const check = BlockedTime?.find((item) => item?._id === id);
    console.log("BlockedTimeuse", check);
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  const checkDate = (Date) => {
    console.log("ljndfinid23", Date);
  };

  const handleClickTabs = async () => {
    let data = {
      service_type: tabData,
    };
    const res = await HomeServices.serviceTypeId(data);
    console.log("resNAme", res);
    if (res && res?.status) {
      if (res?.data?.[1]?.isUrgent === true) {
        console.log("lkdj57hfjkgnlk", res?.data?.[1]?._id);
        setServiceType(res?.data?.[1]?._id);
      }
    }
  };

  const availableByDatetime = async (date) => {
    console.log("first684987984");
    let data = {
      booking_serviceType_id: serviceType,
      date: date,
    };
    const res = await HomeServices.viewBookingTimeByDate(data);
    console.log("jksdfaqtbdk", res?.data);
    if (res && res?.status) {
      setServiceDateTime(res?.data);
      const timeSlots = res?.data;
      setTimeScheduleArr([
        {
          serHead: "Timing",
          serTimeArr: timeSlots,
        },
      ]);
    }
  };

  const fetchViewBlockDate = async () => {
    const res = await HomeServices.viewUrgentblockDateTime(serviceType);
    console.log("mnbfvjh7847ghe", res?.data);
    if (res && res?.status) {
      setViewBlockDate(res?.data);
    }
  };

  const blockDate = (date) => {
    const formatdate = moment(date).format("YYYY-MM-DD");
    const check = viewBlockdate.find((item) => item?.block_date === formatdate);
    console.log("fngjkiuy87yhuk", viewBlockdate, formatdate, check);
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  // const isWeekend = (date) => date.getDay() === 0;

  const isWeekend = (date) => date.getDay() === 0 || blockDate(date);

  const handleChange = (e) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (e < currentDate) {
      toast.error("Please select a Current Date or Upcoming Date.");
    } else if (isWeekend(e)) {
      toast.error("You can't select a date on Saturday or Sunday!");
    } else {
      setBlockDateTime({
        ...blockDateTime,
        block_date: moment(e).format("YYYY-MM-DD"),
      });
      setSelectedDate(moment(e).format("YYYY-MM-DD"));
      viewBlockByDateTime(moment(e).format("YYYY-MM-DD"));
      availableByDatetime(moment(e).format("YYYY-MM-DD"));
    }
  };

  const tileDisabled = ({ date }) => {
    console.log("isWeekend", isWeekend(date));
    return isWeekend(date);
  };

  const fetchBlockedDate = async () => {
    try {
      // Call your API function
      const response = await HomeServices.viewUrgentblockDateTime(serviceType);
      console.log("Response986532", response);
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          console.log("56465sdbjhksd", ele);
          return {
            sln: index + 1,
            block_date: ele?.block_date,
            action: (
              <>
                <span
                  title="Delete Blocked date"
                  onClick={() => handleDelete(ele?._id)}
                >
                  <i
                    className="fa-regular fa-trash-can"
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      padding: "5px",
                      fontSize: "1.5em",
                      color: "#C4554D",
                      backgroundColor: "#FAECEC",
                    }}
                  ></i>
                </span>
              </>
            ),
          };
        });
        setAdminBlockedDate(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    if (serviceType) {
      fetchViewBlockDate();
      fetchBlockedDate();
    }
  }, [serviceType]);

  useEffect(() => {
    handleClickTabs();
  }, [tabData]);

  useEffect(() => {
    if (selectedDate) {
      availableByDatetime(selectedDate);
    } else {
      fetchTime();
    }
  }, [selectedDate]);

  const tableProps = {
    columns,
    data: adminBlockedDate,
    fixedHeader: true,
    fixedHeaderScrollHeight: "300px",
  };

  const handleDelete = async (id) => {
    const res = await HomeServices.urgentDeleteBlockedDate(id);
    if (res && res?.status) {
      toast.success("Unblocked Successfully");
      fetchBlockedDate();
    } else if (res?.status === false) {
      toast.error("dfsdfsd");
    }
  };

  const getAllBlockedDateTime = async () => {
    let data = {
      block_date: blockDateTime?.block_date,
      block_time: blockedIds,
    };
    const res = await HomeServices.urgentDeleteAllBlockedDate(data);
    if (res && res?.status) {
      toast.success("Time Deleted Successfully");
      window.location.reload();
    } else {
      toast.error("Unable to delete time");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
            <div className="tablistDiv">
              <p className="seleSerText">Select a service</p>
              <div className="slectAftTab">
                <Tabs>
                  <TabList>
                    <Tab onClick={() => setTabData("phone")}>Phone</Tab>
                    <Tab onClick={() => setTabData("in_person_office")}>
                      In Person - Office
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
            <section className="predateSection">
              <div className="cust_container">
                <div className="row">
                  <div className="col-12">
                    <p className="slYuHead">Urgent Blocked Date and Time</p>
                    <div className="selectClndrDiv">
                      <Calendar
                        onChange={handleChange}
                        value={blockDateTime?.date}
                        tileDisabled={tileDisabled}
                      />
                    </div>

                    <div className="timeScheduleDiv">
                      {timeScheduleArr.map((value, index, arr) => {
                        console.log("timeScheduleArr", value);
                        return (
                          <div
                            style={{
                              marginBottom: `${
                                index === arr.length - 1 ? 0 : 18
                              }px`,
                            }}
                          >
                            <p className="sevMorHead">{value.serHead}</p>
                            <div
                              className="sevTimeAmDiv "
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {}}
                            >
                              {value.serTimeArr.length === 0 ? (
                                <div className="sevTimeAm">
                                  <span>All Booked</span>
                                </div>
                              ) : (
                                value?.serTimeArr?.map((item, i) => {
                                  console.log("itemfgs16", item);
                                  return (
                                    <div
                                      key={i}
                                      className={`sevTimeAmBlock ${
                                        item?.isBooked === true
                                          ? "sevTimeAmSelectColor"
                                          : check === i + 1
                                          ? "sevTimeAmSelectColor"
                                          : checkedBook(item?._id) === true
                                          ? "sevTimeAmSelectColor"
                                          : "sevTimeAmColor"
                                      }`}
                                      onClick={() => handleTimeSelection(item)}
                                    >
                                      <span
                                        className={
                                          checkedBook(item?._id) === true
                                            ? " "
                                            : ""
                                        }
                                        style={{
                                          margin: "5px",
                                          padding: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setBlockIds((prev) => [
                                            ...prev,
                                            item?._id,
                                          ]);
                                          toggleSelectedTime(item?._id);
                                        }}
                                      >
                                        {selectedTimes.includes(item?._id) && (
                                          <span>&#10003;</span>
                                        )}
                                        {item?.time}
                                        {checkedBook(item?._id) ? (
                                          <span
                                            className={
                                              checkedBook(item?._id)
                                                ? "disableDiv"
                                                : "sevTimeAmSelectColor"
                                            }
                                            style={{
                                              color: "#4d7902",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {/* {`Pre Booked`} */}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>{" "}
                                      {item?.isBooked === true ? (
                                        <span
                                          style={{
                                            color: " #4d7902",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {/* Booked */}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="serMDiv">
                      <p className="sevMorHead"></p>
                      <div>
                        <button
                          onClick={fetchBlockDateTime}
                          style={{ border: "none" }}
                          className="joinWaitLink m-2"
                        >
                          Blocked
                        </button>

                        <button
                          className=" joinWaitLinked "
                          style={{ border: "none" }}
                          onClick={() => {
                            setModal(true);
                            fetchBlockedDate();
                          }}
                        >
                          Delete Date
                        </button>

                        <button
                          className=" joinWaitLinked mx-2"
                          style={{ border: "none" }}
                          onClick={() => {
                            setBlockedDateTimeModal(true);
                            fetchBlockedDate();
                          }}
                        >
                          Delete Date-Time
                        </button>
                      </div>

                      {/* Unblocked Date Modal */}
                      <Modal
                        size="lg"
                        isOpen={modal}
                        toggle={() => setModal(!modal)}
                      >
                        <ModalHeader toggle={() => setModal(!modal)}>
                          <h2 style={{ color: "#800000" }}>
                            Urgent Delete Date
                          </h2>
                        </ModalHeader>
                        <ModalBody>
                          <section className="piechartsBox_area">
                            <h2>Blocked Date</h2>
                            <hr />
                            <DataTable
                              columns={columns}
                              data={adminBlockedDate}
                              {...tableProps}
                              pagination
                            />
                          </section>
                        </ModalBody>
                      </Modal>

                      {/* Unblocked Date And Time Modal */}
                      <Modal
                        size="lg"
                        isOpen={blockedDateTimeModal}
                        toggle={() =>
                          setBlockedDateTimeModal(!blockedDateTimeModal)
                        }
                      >
                        <ModalHeader
                          toggle={() =>
                            setBlockedDateTimeModal(!blockedDateTimeModal)
                          }
                        >
                          <h2 style={{ color: "#800000" }}>
                            Urgent Delete Time
                          </h2>
                        </ModalHeader>
                        <ModalBody>
                          <div className="selectClndrDiv">
                            <Calendar
                              onChange={handleChange}
                              value={blockDateTime?.date}
                              tileDisabled={tileDisabled}
                            />
                          </div>

                          <div className="timeScheduleDiv">
                            {timeScheduleArr.map((value, index, arr) => {
                              console.log("timeScheduleArr", value);
                              return (
                                <div
                                  style={{
                                    marginBottom: `${
                                      index === arr.length - 1 ? 0 : 18
                                    }px`,
                                  }}
                                >
                                  <p className="sevMorHead">{value.serHead}</p>
                                  <div
                                    className="sevTimeAmDiv "
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {}}
                                  >
                                    {value.serTimeArr.length === 0 ? (
                                      <div className="sevTimeAm">
                                        <span>All Booked</span>
                                      </div>
                                    ) : (
                                      value?.serTimeArr?.map((item, i) => {
                                        console.log("itemfgs16", item);
                                        return (
                                          <div
                                            key={i}
                                            className={`sevTimeAmBlock ${
                                              item?.isBooked === true
                                                ? "sevTimeAmSelectColor"
                                                : check === i + 1
                                                ? "sevTimeAmSelectColor"
                                                : checkedBook(item?._id) ===
                                                  true
                                                ? "sevjsfyjsefbj"
                                                : "sevTimeAmColor"
                                            }`}
                                            onClick={() =>
                                              handleTimeSelection(item)
                                            }
                                          >
                                            <span
                                              style={{
                                                margin: "5px",
                                                padding: "5px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setBlockIds((prev) => [
                                                  ...prev,
                                                  item?._id,
                                                ]);
                                                toggleSelectedTime(item?._id);
                                              }}
                                            >
                                              {selectedTimes.includes(
                                                item?._id
                                              ) && <span>&#10003;</span>}
                                              {item?.time}
                                              {checkedBook(item?._id) ? (
                                                <span
                                                  style={{
                                                    color: "#4d7902",
                                                    fontWeight: "bold",
                                                    paddingLeft: "2px",
                                                  }}
                                                >
                                                  {/* {`Pre Booked`} */}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </span>{" "}
                                            {item?.isBooked === true ? (
                                              <span
                                                style={{
                                                  color: " #4d7902",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {/* Booked */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        );
                                      })
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div>
                            <button
                              onClick={getAllBlockedDateTime}
                              className="joinWaitLinked"
                              style={{ border: "none" }}
                            >
                              Delete
                            </button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </>
  );
};

export default UrgentBlockDateTime;
