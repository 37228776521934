import React, { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import CustomLoader from "../../CustomComponents/loader/CustomLoader";
import HttpClientXml from "../../utils/HttpClientXml";
import HomeServices from "../../Services/HomeServices";
import "./Pages.css";
import Swal from "sweetalert2";
import { Form, Link, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BackPageBtn from "../BackButton/BackPageBtn";

const AdBlog = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const imgURL = "https://api.deshvidesh.ca";
  const Upload_Endpoint = "api/admin/upload-blog-image";
  const initValue = {
    title: "",
    description: "",
    image1: "",
    image2: "",
  };
  const [formValue, setFormValue] = useState(initValue);
  const [blogData, setBlogData] = useState();
  const [blogId, setBlogId] = useState("");
  const [viewId, setViewId] = useState("");
  const [imageFile, setimageFile] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [showHead, setShowHead] = useState(false);

  console.log("jhsgdfjhakd", blogId);

  const [isEdit, setIsEdit] = useState(true);
  const [editData, setEditData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };
  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("alldasdasdd", formValue);
    // return
    const { title, description } = formValue;
    const isValid = title.trim() !== "" && description.trim() !== "";

    if (isValid) {
      if (formValue?.image1 === "" || formValue?.image2 === "") {
        return toast.error("Please Upload Images");
      } else {
        const res = await HomeServices.addBlog(formValue);
        // console.log("resCat", res)
        if (res && res?.status) {
          toast.success("Blog Added Successfully");
          fetchData();
          let file = document.querySelector("#image1");
          file.value = "";
          let files = document.querySelector("#image2");
          files.value = "";
          setFormValue(initValue);
          setimageFile("");
          // navigate('/manage-category');
          setIsLoading(false);
          console.log("formValue21456", formValue);
        } else {
          toast.error(res?.message || "Something Wrong");
          setIsLoading(false);
        }
      }
    } else {
      toast.error("Please Fill All The Inputs, Including the Image.");
    }
  };

  // Function to upload an image and return its URL
  const uploadImage = async (image) => {
    if (!image) {
      return "";
    }

    const formData = new FormData();
    formData.append("file", image);

    try {
      const res = await HttpClientXml.fileUplode(
        "upload-blog-image",
        "POST",
        formData
      );

      if (res && res.status) {
        return `${imgURL}${res?.data}`;
      } else {
        toast.error(res?.message || "Image upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Image upload failed");
      return "";
    }
  };

  // Function to reset the form values
  const resetForm = () => {
    let file1 = document.querySelector("#image1");
    file1.value = "";
    let file2 = document.querySelector("#image2");
    file2.value = "";
    setFormValue(initValue);
    setimageFile("");
  };

  const columns = [
    { name: "SL No.", selector: "sln", sortable: true },
    { name: "Blog Title", selector: "title", sortable: true },
    // { name: "Description", selector: "description", sortable: true },
    { name: "Image-1", selector: "image1", sortable: true },
    { name: "Image-2", selector: "image2", sortable: true },
    { name: "Action", selector: "action", sortable: true },
  ];

  const fetchData = async () => {
    try {
      // Call your API function
      const response = await HomeServices.getBlog();
      if (response && response?.status) {
        let arr = response?.data?.reverse()?.map((ele, index) => {
          console.log("mnbdvjd", ele);
          return {
            sln: index + 1,
            title: ele?.title,
            // description: `${ele?.description.slice(0, 50)}...`,
            image1: (
              <img
                src={ele?.image1}
                style={{ width: "50px", height: "50px" }}
              />
            ),
            image2: (
              <img
                src={ele?.image2}
                style={{ width: "50px", height: "50px" }}
              />
            ),
            action: (
              <>
                <div>
                  {/* View Icon */}
                  <span title="view" onClick={(e) => handleView(e, ele)}>
                    <i
                      className="fa-regular fa-eye"
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        marginRight: "10px",
                        fontSize: "1.5em",
                        color: " #477DA5",
                        backgroundColor: "#E9F3F7",
                      }}
                    ></i>
                  </span>

                  {/* Edit Icon */}
                  <span title="Edit" onClick={(e) => handleEdit(e, ele)}>
                    <i
                      className="fa-regular fa-pen-to-square"
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        marginRight: "10px",
                        fontSize: "1.5em",
                        color: " #477DA5",
                        backgroundColor: "#E9F3F7",
                      }}
                    ></i>
                  </span>

                  {/* Delete Icon */}
                  <span title="Delete" onClick={() => handleDelete(ele?._id)}>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        padding: "5px",
                        fontSize: "1.5em",
                        color: "#C4554D",
                        backgroundColor: "#FAECEC",
                      }}
                    ></i>
                  </span>
                </div>
              </>
            ),
          };
        });
        setBlogData(arr);
      }
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("".formValue);

  const handleImage = async (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    const res = await HttpClientXml.fileUplode(
      "upload-blog-image",
      "POST",
      formData
    );
    console.log("resimage", res);
    if (res && res?.status) {
      // setImageUrl(res?.data);
      setFormValue({
        ...formValue,
        image1: res?.data,
      });
      toast.success("Image Uploaded Successfully");
      setIsLoading(false);
    } else {
      toast.error(res?.message || "Something Wrong");
      setIsLoading(false);
    }
  };

  const handleImage1 = async (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    const res = await HttpClientXml.fileUplode(
      "upload-blog-image",
      "POST",
      formData
    );
    console.log("resimage", res);
    if (res && res?.status) {
      setFormValue({
        ...formValue,
        image2: res?.data,
      });
      toast.success("Image Uploaded Successfully");
      setIsLoading(false);
    } else {
      toast.error(res?.message || "Something Wrong");
      setIsLoading(false);
    }
  };

  const handleView = (e, ele) => {
    e.preventDefault();
    setViewId(ele?._id);
    navigate("/view-blog", { state: ele });
  };

  // click on edit
  const handleEdit = (e, ele) => {
    // navigate("/edit-advocacy", { state: item })
    // window.alert(5)

    e.preventDefault();
    window.scrollTo(0, 0);
    setShowHead(true);
    console.log("itemsd", ele?._id);
    setBlogId(ele?._id);
    // console.log("first",ele?.title)
    setFormValue(ele);
    // setEditData(ele);
    // setIsEdit(true);
    // setFormValue({
    //   BlogName: item?.advocacyBlogName,
    //   title: item?.AdvocacyName,
    //   description: item?.desc,
    //   image: item?.image,
    //   pdf: item?.material,
    // });
    // setimageFile(item?.image);
  };

  const handleDelete = async (id) => {
    console.log("deleteblogid", id);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log("result", result);
      if (result?.isConfirmed) {
        HomeServices.deleteBlog(id)
          .then((res) => {
            console.log("deleteBlogresponse", res);

            if (res && res.status) {
              toast.success("Deleted Successfully");
              fetchData();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((err) => {
            console.error("Error deleting blog:", err);
          });
      } else {
        console.log("Deletion canceled");
      }
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { title, description } = formValue;
    const isValid = title.trim() !== "" && description.trim() !== "";
    if (isValid) {
      if (formValue?.image1 === "" && formValue?.image2 === "") {
        return toast.error("Please Upload Images");
      } else {
        let data = {
          title: formValue?.title,
          description: formValue?.description,
          image1: formValue?.image1,
          image2: formValue?.image2,
        };
        await HomeServices.updateBlog(blogId, data)
          .then((res) => {
            if (res && res?.status) {
              console.log("updateBlogresponse", res);
              toast.success("update successfully");
              let file = document.querySelector("#image1");
              file.value = "";
              let files = document.querySelector("#image2");
              files.value = "";
              setFormValue(initValue);
              setShowHead(false);
              fetchData();
            } else {
              toast.error(res?.message || "Failed to update Blog");
            }
          })
          .catch((err) => {
            toast.error(err?.message || "An error occurred");
            console.error("err", err);
          });
      }
    } else {
      console.log("Inputs are not valid");
      toast.error("Please Fill All The Inputs..!");
    }
  };

  const removeImage = (e) => {
    setFormValue({
      ...formValue,
      image1: "",
    });
    let file = document.querySelector("#image1");
    file.value = "";
  };
  const removeImage2 = (e) => {
    setFormValue({
      ...formValue,
      image2: "",
    });
    let file = document.querySelector("#image2");
    file.value = "";
  };

  return (
    <>
      <CustomLoader loading={isLoading} />

      <div className="d-flex justify-content-end mt-5">
        <div className="form-header">
          <section className="piechartsBox_area">
            {/* <BackPageBtn backLink="/" /> */}

            <form>
              {!showHead ? (
                <div>
                  <h1 style={{ color: "#ff9c00" }}>Add Blog</h1>
                </div>
              ) : (
                <div>
                  <h1 style={{ color: "#ff9c00" }}>Update Blog</h1>
                  {/* <BackPageBtn backLink="/add-blog" /> */}
                </div>
              )}
              <div className="row mt-4">
                <div className="col">
                  <label htmlFor="formGroupExampleInput">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Blog Title"
                    name="title"
                    value={formValue?.title}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label htmlFor="formGroupExampleInput">Description</label>
                  <CKEditor
                    className="ckEditor"
                    editor={ClassicEditor}
                    data={formValue?.description}
                    // config={{
                    //   extraPlugins: [uploadPlugin],
                    // }}
                    onChange={(event, editor) => {
                      const content = editor.getData();
                      setFormValue((prev) => ({
                        ...prev,
                        description: content,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5" style={{ marginTop: "10px" }}>
                <div className="col">
                  <label
                    htmlFor="formGroupExampleInput"
                    style={{ marginRight: "5px" }}
                  >
                    Image-1
                  </label>
                  <input
                    type="file"
                    name="image1"
                    id="image1"
                    accept="image/*"
                    onChange={(e) => handleImage(e)}
                  />
                  <br></br>
                  {formValue?.image1 && (
                    <div>
                      <img
                        alt="not found"
                        className="mt-2"
                        width={"250px"}
                        src={formValue?.image1}
                      />
                      <button
                        className="rmvBtn"
                        title="remove"
                        onClick={(e) => removeImage(e)}
                      >
                        x
                      </button>
                    </div>
                  )}
                </div>
                <div className="col">
                  <label
                    htmlFor="formGroupExampleInput"
                    style={{ marginRight: "5px" }}
                  >
                    Image-2
                  </label>
                  <input
                    type="file"
                    name="image2"
                    id="image2"
                    accept="image/*"
                    onChange={(e) => handleImage1(e)}
                  />
                  <br></br>
                  {formValue?.image2 && (
                    <div>
                      <img
                        alt="not found"
                        width={"250px"}
                        src={formValue?.image2}
                      />
                      <br />
                      <button
                        className="RmvBtn"
                        title="remove"
                        onClick={(e) => removeImage2(e)}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div class="col-12 ">
                {!showHead ? (
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    class="btn btn-primaryb mt-5 mb-4 addBlogBn"
                    style={{
                      background: "linear-gradient(195deg, #FFB14C, #ff9000)",
                      color: "#fff",
                    }}
                  >
                    Add Blog
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                    class="btn btn-primaryb mt-3 addBlogBtn"
                    style={{
                      background: "linear-gradient(195deg, #FFB14C, #ff9000)",
                      color: "#fff",
                    }}
                  >
                    Update Blog
                  </button>
                )}
              </div>
            </form>
          </section>
        </div>
      </div>

      {/* <ManageAdvocacy
                handleEdit={handleEdit}

            /> */}
      <div className="d-flex justify-content-end">
        <div className="form-header">
          <section className="piechartsBox_area">
            <DataTable columns={columns} data={blogData} pagination />
          </section>
        </div>
      </div>
    </>
  );
};

export default AdBlog;
