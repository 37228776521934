import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackPageBtn from "../BackButton/BackPageBtn";

const View_Waitlist_Appointment_Data = () => {
  const [viewContactData, setViewContactData] = useState("");
  const location = useLocation();

  useEffect(() => {
    console.log("ytweqrtw798", location?.state); 
    setViewContactData(location.state);
  }, [location?.state]);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
          <BackPageBtn backLink="/view-user-waitlist-appointment" />

            <h1 style={{color:"#ff9c00"}}>View User WaitList Appointment Detail</h1>
            <hr />
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="firstName">Service Type</label>
                  </td>
                  <td>
                    <span>
                      {viewContactData?.bookingAppointmentService?.service_type}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="firstName">First Name</label>
                  </td>
                  <td>
                    <span>{viewContactData?.firstName}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="lastName">Last Name</label>
                  </td>
                  <td>
                    <span>{viewContactData?.lastName}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="email">Email</label>
                  </td>
                  <td>
                    <span>{viewContactData?.email}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Mobile Code</label>
                  </td>
                  <td>
                    <span>{viewContactData?.mobileCode}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Mobile Number</label>
                  </td>
                  <td>
                    <span>{viewContactData?.mobileNumber}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="mobileNumber">Country</label>
                  </td>
                  <td>
                    <span>{viewContactData?.country}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="note">Booking Time</label>
                  </td>
                  <td>
                    <span>
                      {viewContactData?.BookingTime
                        ? new Date(
                            `2022-01-01T${viewContactData?.BookingTime}`
                          ).getHours() < 12
                          ? `${viewContactData?.BookingTime} AM`
                          : `${viewContactData?.BookingTime} PM`
                        : ""}
                    </span> 
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="note">Start Date</label>
                  </td>
                  <td>
                    <span>{viewContactData?.startDate}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="note">End Date</label>
                  </td>
                  <td>
                    <span>{viewContactData?.endDate}</span>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <label htmlFor="note">Note</label>
                  </td>
                  <td>
                    <span>{decodeURIComponent(viewContactData?.note)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
};

export default View_Waitlist_Appointment_Data;
