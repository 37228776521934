import React, { useEffect, useState } from 'react'
import AuthServices from '../../Services/AuthServices';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';

const initialData = {
    name: ""
}

const OwnProfile = () => {

    const [loginProfile, setLoginProfile] = useState({ name: "", email: "", password: "" });
    const [isUpdate, setisUpdate] = useState(false)

    console.log(loginProfile, "loginprofile")

    const fetchLoginProfile = async () => {
        const res = await AuthServices.getloginProfile();
        if (res && res?.status) {
            setLoginProfile(prev => ({ ...prev, name: res?.data?.name, email: res?.data?.email }));
        }
        // console.log("loginProfileResponse",res?.data?.name, res?.data?.email)
    };

    // value change
    const handlechange = (e) => {
        const { name, value } = e.target;
        setLoginProfile((preValu) => ({ ...preValu, [name]: value }))
    }

    // submit
    const editSubmit = async (e) => {
        e.preventDefault()
        const data =
        {
            "name": loginProfile?.name,
            "email": loginProfile?.email,
            "password": loginProfile?.password
        }

        const res = await HttpClientXml.requestData("update-profile", data, "PUT")

        console.log(res, "resdata")
        if (res && res?.status) {
            setisUpdate(!isUpdate)
            toast.success('Profile Updated Successfully')
            fetchLoginProfile()
        }
        else {
            toast.error(res?.msg)
        }
    }

    useEffect(() => {
        fetchLoginProfile();
    }, []);
    return (
        <div className="d-flex justify-content-end mx-2">
            <div className="form-header">
                <section className="piechartsBox_area">
                    {/* <BackPageBtn backLink="/" /> */}

                    <div className="dateListDiv row">
                        <h1
                            style={{
                                color: "#ff9c00",
                                fontSize: "25px",
                                fontWeight: "600",
                                marginBottom: "10px",
                            }}
                        >
                            View & Edit Admin Profile
                        </h1>

                        {/* <Tab>Any Date</Tab> */}
                        {/* <Tab>Select Date Range</Tab> */}

                        {/* <TabPanel></TabPanel> */}

                        <div className="tabRngeDivmain  ">
                            <div className="strtdivmain  ">
                                {/* <label htmlFor="startDate">Start Date</label> */}
                                <p className="strttxt">Name</p>
                                {isUpdate ? <input
                                    type="text"
                                    name="name"
                                    value={loginProfile?.name}
                                    onChange={handlechange}

                                /> :
                                    <input
                                        type="text"
                                        name="name"
                                        value={loginProfile?.name}
                                        onChange={handlechange}
                                        readOnly

                                    />}
                            </div>
                            {/* <span>-</span> */}
                            <div className="strtdivmain">
                                <p className="strttxt">Email</p>
                                {isUpdate ? <input
                                    type="email"
                                    name="email"
                                    value={loginProfile?.email}
                                    onChange={handlechange}
                                // onChange={(e) => setEndDate(e.target.value)}
                                /> :
                                    <input
                                        type="email"
                                        name="email"
                                        value={loginProfile?.email}
                                        onChange={handlechange}
                                        readOnly
                                    // onChange={(e) => setEndDate(e.target.value)}
                                    />}
                            </div>
                            <div className="strtdivmain">
                                <p className="strttxt">Password</p>
                                {isUpdate ? <input
                                    type="password"
                                    name="password"
                                    value={loginProfile?.password}
                                    onChange={handlechange}
                                // onChange={(e) => setEndDate(e.target.value)}
                                /> :
                                    <input
                                        type="password"
                                        name="password"
                                        value={loginProfile?.password}
                                        readOnly
                                        onChange={handlechange}
                                    // onChange={(e) => setEndDate(e.target.value)}
                                    />}
                            </div>



                        </div>
                        <div className="tabRngeDivmain mt-5 ">
                            <br />
                            {!isUpdate && <button
                                // onClick={filterDataByDateRange}
                                className="Search_icon btn btn-primary"
                                type="button"
                                onClick={() => setisUpdate(!isUpdate)}
                            >
                                Update
                            </button>}
                            {isUpdate && <button
                                // onClick={filterDataByDateRange}
                                className="Search_icon btn btn-success"
                                type="button"
                                onClick={(e) => editSubmit(e)}
                            >
                                Submit
                            </button>}
                        </div>
                    </div>
                    <hr />

                </section>
            </div>
        </div>
    )
}

export default OwnProfile