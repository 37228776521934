import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import BackPageBtn from "../BackButton/BackPageBtn";


const ViewUrgentApointmentData = () => {
    const [viewContactData, setViewContactData] = useState("");
    const [typeData, setTypeData] = useState('')
    const [editedNote, setEditedNote] = useState('');
    const location = useLocation();
    console.log("jhfjhsbdkjs6546", editedNote);

    const handleNote = () => {
        setViewContactData(location?.state)
        setTypeData(location?.state)

        const changeNote = location?.state?.note;
        const myNote = changeNote?.replaceAll('_', " ")
        console.log("sfgsdfgs45", myNote)
        setEditedNote(myNote)
        // return myNote
    }

    useEffect(() => {
        handleNote();
    }, []);
    return (
        <div className="d-flex justify-content-end mx-2">
            <div className="form-header">
                <section className="piechartsBox_area">
                    <BackPageBtn backLink="/view-user-urgent-appointment" />
                    <hr />
                    <h1 style={{ color: "#ff9c00", fontSize: "30px" }}>View User Urgent Book Appointment Detail</h1>
                    <hr />
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="firstName">Service Type</label>
                                </td>
                                <td>
                                    <span>
                                        {`${typeData?.bookingAppointmentService?.service_type} ${typeData?.bookingAppointmentService?.isUrgent === true ? "( Urgent )" : ""}`}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="firstName">First Name</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.firstName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="lastName">Last Name</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.lastName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="email">Email</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.email}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mobileNumber">Mobile Code</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.mobileCode}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mobileNumber">Mobile Number</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.mobileNumber}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mobileNumber">Booked Date</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.date}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mobileNumber">Booking Time</label>
                                </td>
                                <td>
                                    <span>
                                        {viewContactData?.BookingTime
                                            ? new Date(
                                                `2022-01-01T${viewContactData?.BookingTime}`
                                            ).getHours() < 12
                                                ? `${viewContactData?.BookingTime} AM`
                                                : `${viewContactData?.BookingTime} PM`
                                            : ""}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="mobileNumber">Country</label>
                                </td>
                                <td>
                                    <span>{viewContactData?.country}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="note">Note</label>
                                </td>
                                <td>
                                    <span>{editedNote}</span>
                                    {/* <span>{viewContactData?.[0]?.note}</span> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    )
}

export default ViewUrgentApointmentData