import React, { useEffect, useState } from "react";
import HomeServices from "../../Services/HomeServices";
import { useLocation } from "react-router-dom";
// import Slider from "react-slick";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import BackPageBtn from "../BackButton/BackPageBtn";

const View_Blog = () => {
  const [viewBlogData, setViewBlogData] = useState("");
  console.log("xxxxx654654", viewBlogData);
  const location = useLocation();

//   const images = [
//     "https://example.com/image1.jpg",
//     "https://example.com/image2.jpg",
//     "https://example.com/image3.jpg",
//     // Add more image URLs as needed
//   ];
//   const fetchData = async () => {
//     // e.preventDefault();
//     const response = await HomeServices.getBlog();
//     console.log("mndbvfdk899", response);
//   };

  useEffect(() => {
    // e.preventDefault();
    //    fetchData();
    setViewBlogData(location.state);
    // console.log("nasbvghsj5454", location.state);
  }, []);

   

  return (
    <>
      <div className="d-flex justify-content-end mx-2">
        <div className="form-header">
          <section className="piechartsBox_area">
          <BackPageBtn backLink="/add-blog" />

            <div>
              <h1 style={{color:"#ff9c00"}}>View Blog</h1>
            </div>
            <hr />       
            <h1>{viewBlogData?.title}</h1>
            
          {/* <p >{viewBlogData?.description ? viewBlogData.description.replace(/<p>/g, '').replace(/<\/p>/g, '') : ''}</p> */}
          <div dangerouslySetInnerHTML={{__html: viewBlogData?.description}}></div>

            <div className="d-flex flex-row bd-highlight mb-3">
                <div className="mx-2">
                    <img src={viewBlogData?.image1} alt="..." style={{width:"400px", height:"300px"}} />
                </div>

                <div>
                    <img src={viewBlogData?.image2} alt="..." style={{width:"400px", height:"300px"}} />
                </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default View_Blog;
