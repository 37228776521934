import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Pages.css";
import BackPageBtn from "../BackButton/BackPageBtn";
 
const ViewUserDetail = () => {
  const [viewContactData, setViewContactData] = useState("");
  const location = useLocation();

  useEffect(() => {
    setViewContactData(location.state);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end mx-2">
      <div className="form-header">
        <section className="piechartsBox_area">
        <BackPageBtn backLink="/view-user-contact" />

          <h1 style={{color:"#ff9c00"}}>View User Contact Detail</h1>
          <hr />
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="firstName">First Name</label>
                </td>
                <td>
                  <span>{viewContactData?.firstName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="lastName">Last Name</label>
                </td>
                <td>
                  <span>{viewContactData?.lastName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="email">Email</label>
                </td>
                <td>
                  <span>{viewContactData?.email}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="mobileNumber">Mobile Number</label>
                </td>
                <td>
                  <span>{viewContactData?.mobileNumber}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="mobileNumber">Mobile Code</label>
                </td>
                <td>
                  <span>{viewContactData?.mobileCode}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="mobileNumber">Country</label>
                </td>
                <td>
                  <span>{viewContactData?.country}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="note">Note</label>
                </td>
                <td>
                  <span>{viewContactData?.note}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
      
    </>
  );
};

export default ViewUserDetail;
